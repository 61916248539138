<script>
    export default {
        name: "SkeletonProductDeliveryItem",
        data() {
            return {
                thumbsCount: 3,
            };
        },
    };
</script>

<template>
    <div class="skeleton-product-delivery">
        <div v-for="thumb in thumbsCount" :key="thumb" class="skeleton-product-delivery__row">
            <div
                class="
                    skeleton-product-delivery__item skeleton-product-delivery__item--1
                    skeleton-animation
                "
            ></div>
            <div
                class="
                    skeleton-product-delivery__item skeleton-product-delivery__item--2
                    skeleton-animation
                "
            ></div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "style";

    .skeleton-product-delivery {
        width: 100%;
    }

    .skeleton-product-delivery__row {
        width: 100%;
        height: 24px;
        display: flex;
        justify-content: space-between;

        &:not(:first-child) {
            margin-top: 8px;
        }
    }

    .skeleton-product-delivery__item {
        border-radius: var(--radius-skeleton-item);
    }

    .skeleton-product-delivery__item--1 {
        width: 60%;
    }

    .skeleton-product-delivery__item--2 {
        width: 15%;
    }
</style>
