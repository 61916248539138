<script>
    import SkeletonMainBanner from "./components/SkeletonMainBanner";
    import SkeletonProduct from "./components/SkeletonProduct";
    import SkeletonProductDeliveryItem from "./components/SkeletonProductDeliveryItem";
    import SkeletonProductPicooc from "./components/SkeletonProductPicooc";

    export default {
        name: "TheSkeleton",
        components: {
            SkeletonMainBanner,
            SkeletonProduct,
            SkeletonProductDeliveryItem,
            SkeletonProductPicooc,
        },
        props: {
            type: {
                type: String,
                required: true,
                validator: (value) => {
                    return [
                        "main-banner",
                        "product",
                        "product-delivery-item",
                        "product-picooc",
                    ].includes(value);
                },
            },
        },
        computed: {
            component() {
                return (
                    "Skeleton" +
                    this.type
                        .split("-")
                        .map((word) => word[0].charAt(0).toUpperCase() + word.slice(1))
                        .join("")
                );
            },
        },
    };
</script>

<template>
    <component :is="component"></component>
</template>
