<script>
    export default {
        name: "SkeletonProduct",
        data() {
            return {
                thumbsCount: 5,
            };
        },
    };
</script>

<template>
    <div class="skeleton-product">
        <div class="skeleton-product__body">
            <div class="skeleton-product__thumbs">
                <div
                    v-for="thumb in thumbsCount"
                    :key="thumb"
                    class="skeleton-product__thumbs-item skeleton-animation"
                ></div>
            </div>
            <div class="skeleton-product__main skeleton-animation"></div>
        </div>
        <div class="skeleton-product__aside">
            <div
                class="skeleton-product__aside-item skeleton-animation"
                :class="[`skeleton-product__aside-item--${index}`]"
                v-for="index in 5"
                :key="index"
            ></div>
        </div>
    </div>
</template>

<style lang="scss">
    @import "style";

    .skeleton-product {
        width: 100%;
        z-index: 1000;

        @include bp($bp-desktop-sm) {
            display: flex;
        }
    }

    .skeleton-product__body {
        margin-top: 12px;
        width: 100%;

        @include bp($bp-desktop-sm) {
            margin-top: 0;
            width: calc(100% - 412px);
        }
    }

    .skeleton-product__main {
        width: 100%;
        padding-top: 100%;
        border-radius: var(--border-lg);

        @include bp($bp-desktop-sm) {
            width: calc(100% - 160px);
            margin-left: auto;
            margin-right: 40px;
            padding-top: 80%;
        }
    }

    .skeleton-product__thumbs {
        display: none;

        @include bp($bp-desktop-sm) {
            display: block;
            position: absolute;
            top: 64px;
            left: 0;
        }
    }

    .skeleton-product__thumbs-item {
        @include box(80px);
        border-radius: var(--border-lg);

        &:not(:first-child) {
            margin-top: 10px;
        }
    }

    .skeleton-product__aside {
        margin-top: 24px;
        padding-top: 36px;

        @include bp($bp-desktop-sm) {
            width: 412px;
            margin-top: 0;
            padding-top: 20px;
        }
    }

    .skeleton-product__aside-item {
        width: 100%;
        height: 24px;
        margin-bottom: 24px;
        border-radius: var(--border-lg);
    }

    //
    // --mod
    //

    .skeleton-product__aside-item--1 {
        width: 95%;
        height: 48px;
        margin-bottom: 10px;

        @include bp($bp-desktop-sm) {
            height: 24px;
        }
    }

    .skeleton-product__aside-item--2 {
        width: 35%;
        height: 24px;
        margin-bottom: 28px;

        @include bp($bp-desktop-sm) {
            height: 20px;
            margin-bottom: 30px;
        }
    }

    .skeleton-product__aside-item--3 {
        width: 45%;
        height: 42px;
        margin-bottom: 34px;
        @include bp($bp-desktop-sm) {
        }
    }

    .skeleton-product__aside-item--4 {
        width: 30%;
        height: 30px;
        margin-bottom: 22px;
        @include bp($bp-desktop-sm) {
        }
    }

    .skeleton-product__aside-item--5 {
        height: 58px;
        @include bp($bp-desktop-sm) {
        }
    }
</style>
