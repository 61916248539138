<script>
    import Slider from "@main/packages/TheSlider";
    import {
        GOODS_BREAKPOINTS,
        GOODS_PRODUCT_BREAKPOINTS,
        EOL_BREAKPOINTS,
    } from "@tb/settings/breakpoints";
    import GoodsCard from "@tb/components/ProductCard";

    export default {
        name: "HomeRowProducts",
        components: {
            GoodsCard,
            Slider,
        },
        props: {
            nameSlider: {
                type: String,
            },
            goodsList: Array,
            breakpoints: {
                default: "home",
                type: String,
            },
        },
        computed: {
            options() {
                const breakpointsList = {
                    home: GOODS_BREAKPOINTS,
                    eol: EOL_BREAKPOINTS,
                };

                const selected =
                    breakpointsList[this.breakpoints] || GOODS_PRODUCT_BREAKPOINTS;

                return {
                    keyboard: {
                        enabled: true,
                    },
                    watchSlidesVisibility: true,
                    slideVisibleClass: "swiper-slide--visible",
                    breakpoints: selected.breakpoints,
                };
            },
        },
    };
</script>

<template>
    <div class="row-goods" :class="{ 'row-goods--product': this.breakpoints === 'product' }">
        <slider
            :name-slider="nameSlider"
            :is-pagination="false"
            :is-arrow="false"
            :options="options"
            :list="goodsList"
            :is-bullets="true"
            :bullets-options="{ type: 'dot', dynamicBullets: false }"
        >
            <div
                slot="swiper-slide"
                class="swiper-slide"
                v-for="goods in goodsList"
                :key="goods.id"
            >
                <goods-card :goods="goods"></goods-card>
            </div>
        </slider>
    </div>
</template>

<style lang="scss">
    .row-goods {
        @include bp($bp-desktop-sm) {
            .swiper-slide {
                transition: visibility 0.3s ease-in;
            }
            .swiper-slide:not(.swiper-slide--visible) {
                visibility: hidden;
            }
        }
    }

    .row-goods--product {
    }
</style>
