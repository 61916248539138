<script>
    export default {
        name: "SkeletonMainBanner",
    };
</script>

<template>
    <div class="skeleton-main-banner skeleton-animation skeleton-animation--absolute"></div>
</template>

<style lang="scss">
    @import "style";

    .skeleton-main-banner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        padding-top: calc(75% + 20px);

        @include bp($bp-desktop-sm) {
            padding-top: 33.34%;
            border-radius: var(--border-xl);
        }
    }
</style>
